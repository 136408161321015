import {
  cloneElement,
  type ComponentProps,
  forwardRef,
  type ReactElement,
} from "react";

import { cn } from "@/styles/lib";

export type InputProps = ComponentProps<"input"> &
  WithChildren<false> & {
    endIcon?: ReactElement;
    hasError?: boolean;
    helperText?: string;
    inputClassName?: string;
    name: string;
    startIcon?: ReactElement;
  };

import { InputHelperText } from "@/components/InputHelperText";
import { generateDataTestId } from "@/lib/tests";
import { type WithChildren } from "@/lib/types";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      helperText,
      hasError,
      children,
      endIcon,
      className,
      startIcon,
      inputClassName,
      ...props
    },
    ref
  ) => (
    <div className={cn("input-wrapper", className)}>
      <div className="relative">
        <input
          {...generateDataTestId("input", name)}
          className={cn(
            "input peer",
            {
              "border-danger text-danger hover:border-danger focus:outline-danger":
                hasError,
              "pr-10": endIcon,
              "pl-10": startIcon,
            },
            inputClassName
          )}
          id={name}
          name={name}
          placeholder=" "
          ref={ref}
          type="text"
          {...props}
        />

        {startIcon &&
          cloneElement(startIcon, {
            className: cn(
              "fill-gray-500 cursor-pointer absolute h-7 vertical-center left-2"
            ),
          })}

        <label
          {...generateDataTestId("label", name)}
          className={cn("floating-label", {
            "text-danger": hasError,
            "ml-8 peer-focus:ml-3 peer-active:ml-3 [input:not(:placeholder-shown)~&]:ml-3":
              startIcon,
          })}
          htmlFor={name}
        >
          {children} {props.required && "*"}
        </label>

        {endIcon &&
          cloneElement(endIcon, {
            className: cn(
              "fill-gray-500 cursor-pointer absolute h-7 vertical-center right-2"
            ),
          })}
      </div>

      {helperText && (
        <InputHelperText
          hasError={hasError}
          isDisabled={props.disabled}
          name={name}
        >
          {helperText}
        </InputHelperText>
      )}
    </div>
  )
);

Input.displayName = "Input";
