"use client";

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { type Dispatch, type SetStateAction, useState } from "react";

import { createSafeContext } from "@/lib/context";
import { type WithChildren, type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

const { useContext: useAccordionContext, Provider } = createSafeContext<{
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
}>();

export const AccordionContent = ({
  children,
  className,
}: WithChildren & WithHTMLProp<"className">) => {
  const { isExpanded } = useAccordionContext();

  return (
    <div
      className={cn(
        "grid grid-rows-[0fr] transition-all duration-300 [&>*]:overflow-hidden",
        {
          "grid-rows-[1fr]": isExpanded,
        },
        className
      )}
    >
      <div
        className={cn(
          "opacity-0 transition-opacity delay-150 duration-[400ms]",
          {
            "opacity-100": isExpanded,
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const AccordionTrigger = ({
  children,
  className,
}: WithChildren & WithHTMLProp<"className">) => {
  const { setIsExpanded } = useAccordionContext();

  return (
    <div
      className={cn("flex cursor-pointer justify-between", className)}
      role="button"
      onClick={() => {
        setIsExpanded(isExpanded => !isExpanded);
      }}
    >
      {children}
    </div>
  );
};

export const AccordionIndicator = ({
  className,
}: WithHTMLProp<"className">) => {
  const { isExpanded } = useAccordionContext();

  return (
    <ChevronDownIcon
      className={cn(
        "transition-transform",
        { "rotate-180": isExpanded },
        className
      )}
      height={20}
      width={20}
    />
  );
};

export const Accordion = ({
  children,
  defaultExpanded,
  className,
}: WithChildren &
  WithHTMLProp<"className"> & { defaultExpanded?: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded ?? false);

  return (
    <div className={className}>
      <Provider value={{ isExpanded, setIsExpanded }}>{children}</Provider>
    </div>
  );
};
