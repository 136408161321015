
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"02e332749fce3a4d4c8420702d49fa3d75bc56f8":"checkoutEmailUpdateAction","089b9a91abb8ff996dca5e6846d3b48263fb5698":"checkoutLinesDeleteAction","239686e618ff8c7e53a1b260e08170452b26fbba":"checkoutCreateAction","516b3f4140f0ae70d98ab0da3a140090021534f5":"checkoutCompleteRedirectAction","51d8c1afd54c8e2b83f38a7118bc5272ae495026":"$$ACTION_7","57aabaeceae618ca503a18102dd983fd449f1c62":"checkoutPromoCodeAddAction","58290915daffbeaeca3fcc8d3da233b83f52c1d5":"$$ACTION_4","5d79458aa7d5077b5a948fc47a17f3be5d035066":"$$ACTION_12","5fd67d79828fa6b9e5645364baae5dd1ada223c4":"$$ACTION_2","6a4c6e3a6bf40f4d465139db74875523a76e82b8":"$$ACTION_5","74f6ffe98a90d669ffbd780dd0f8a1a619eeed50":"$$ACTION_8","7a63bf89426ab684ad13ad73410df0d96583f8b7":"checkoutAddressUpdateAction","892f3c4b2fc22fb8593e30facfef4beb18ad073f":"checkoutLinesAddAction","8e58ad35932627ab458099fba4c17a8c13c615e1":"checkoutLinesUpdateAction","90a06878485da8a703059b6d888950d6e0863da2":"checkoutDeliveryMethodUpdateAction","97af1d9014a401fe5c454e35799690aefb5a6943":"$$ACTION_9","a60afc31d79fd96b4ab6814f1a7788982d99154b":"checkoutCompleteAction","ade5ae620171333d031beabf8fec8db0a39eb261":"$$ACTION_6","b7c9160e96fb4b8d8d7bddb52f6085bea92c8aaf":"$$ACTION_11","c4fa1024f08c23ee3b637fd36278b59f81bb2b73":"checkoutPaymentCreateAction","e3504bf93fc81cff0042eb34c0e585ca8a2de750":"$$ACTION_3","e91c6524e91c915416973208e7125030cc4a0f7c":"checkoutPromoCodeRemoveAction","ea8aa689d122161fefda529e98c5fed6aa5de385":"$$ACTION_10","eac0d2662fd7eb2ed7c5634b5398a4a37f353cbd":"$$ACTION_0","ecac65805a655b4ff3805b5cf02028194a8c88e1":"revalidateCheckout","eff572d31515478255e01b03bcb694733fdc13e3":"$$ACTION_1"} */ export var checkoutCompleteRedirectAction = createServerReference("516b3f4140f0ae70d98ab0da3a140090021534f5");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var revalidateCheckout = createServerReference("ecac65805a655b4ff3805b5cf02028194a8c88e1");
export var checkoutCreateAction = createServerReference("239686e618ff8c7e53a1b260e08170452b26fbba");
export var checkoutLinesAddAction = createServerReference("892f3c4b2fc22fb8593e30facfef4beb18ad073f");
export var checkoutLinesUpdateAction = createServerReference("8e58ad35932627ab458099fba4c17a8c13c615e1");
export var checkoutLinesDeleteAction = createServerReference("089b9a91abb8ff996dca5e6846d3b48263fb5698");
export var checkoutPromoCodeAddAction = createServerReference("57aabaeceae618ca503a18102dd983fd449f1c62");
export var checkoutPromoCodeRemoveAction = createServerReference("e91c6524e91c915416973208e7125030cc4a0f7c");
export var checkoutEmailUpdateAction = createServerReference("02e332749fce3a4d4c8420702d49fa3d75bc56f8");
export var checkoutAddressUpdateAction = createServerReference("7a63bf89426ab684ad13ad73410df0d96583f8b7");
export var checkoutDeliveryMethodUpdateAction = createServerReference("90a06878485da8a703059b6d888950d6e0863da2");
export var checkoutPaymentCreateAction = createServerReference("c4fa1024f08c23ee3b637fd36278b59f81bb2b73");
export var checkoutCompleteAction = createServerReference("a60afc31d79fd96b4ab6814f1a7788982d99154b");

